<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="SECURITY"
      :properties="properties"
      step-label="security"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedSecurityActions"
        :default-value="properties.action"
        step-label="security"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-select
        v-model="properties.method"
        :items="properties.action === 'ENCRYPT' ? ENCRYPT_METHODS : DECRYPT_METHODS"
        :label="$lang.labels.method"
        outlined
        dense
        required
        data-cy="security-method"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>

    <v-col cols="12" class="pb-3">
      <add-key-value
        type="variables"
        :can-edit="canEdit"
        step-type="SECURITY"
        :data="properties.variables"
        :single-step="step"
        @dataChanged="handleChange('variables', $event)"
      ></add-key-value>
    </v-col>
  </v-row>
</template>

<script>
import {
  ENCRYPT_METHODS,
  DECRYPT_METHODS,
  SECURITY_ACTIONS
} from './constants'

import {
  ActionSelect,
  CredentialsSelect
} from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

export default {
  name: 'SecurityStep',
  components: {
    ActionSelect,
    CredentialsSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  data() {
    return {
      ENCRYPT_METHODS,
      DECRYPT_METHODS
    }
  },
  computed: {
    formattedSecurityActions() {
      return SECURITY_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  methods: {
    handleChange(key, val) {
      if (key === 'action' && val === 'DECRYPT') this.properties.method = 'AES'
      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }
      this.$set(this.properties, key, val)
    }
  }
}
</script>
